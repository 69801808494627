import reportWebVitals from './reportWebVitals';
import React from 'react';
import ReactDOM from "react-dom/client";
import { HashRouter, Route, Routes } from "react-router-dom";
import Auth from "./Authentication/auth";
import "./index.scss";
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loader from "./shade/Loaders/Loaders"
const App = React.lazy(() => import("../src/shade/layouts/App"));
const Switcherapp = React.lazy(() => import("../src/shade/layouts/Switcherapp"));
const Custompages = React.lazy(() => import("../src/shade/layouts/custompages"));
const Dashboard = React.lazy(() =>
  import("./components/Dashboard/Dashboard-1/Dashboard")
);
const Dashboard2 = React.lazy(() =>
  import("./components/Dashboard/Dashboard-2/Dashboard2")
);
const Dashboard3 = React.lazy(() =>
  import("./components/Dashboard/Dashboard-3/Dashboard3")
);
//App
const FreeDirectory = React.lazy(() => import("./components/App/Free Directory/FreeDirectory"));
const Filedetails = React.lazy(() =>
  import("./components/App/File-details/Filedetails")
);
const Filemanager = React.lazy(() =>
  import("./components/App/File-manager/Filemanager")
);

const Subscribers = React.lazy(() =>
  import("./components/App/Subscriber/Subscribers")
);
const ViewSubscriber = React.lazy(() =>
  import("./components/App/ViewSubscriber")
);
const ViewMatrimonySubscriber = React.lazy(() =>
  import("./components/App/ViewMatrimonySubscriber")
);
const Notification = React.lazy(() =>
  import("./components/App/Notification/Notification")
);
const Widgetnotification = React.lazy(() =>
  import("./components/App/Widget-notification/Widget-notification")
);
const Treeview = React.lazy(() => import("./components/App/Treeview/Treeview"));

const Matrimony = React.lazy(() => import("./components/App/Matrimony/Matrimony"));
const BusinessDirectory = React.lazy(() => import("./components/App/BusinessDirectory/BusinessDirectory"));

const Filemanager1 = React.lazy(() =>
  import("./components/App/File-manager1/Filemanager1")
);

const PaidDirectory = React.lazy(() =>
  import("./components/App/PaidDirectory/PaidDirectory")
);
//App end
//Element
const KycCommDirect = React.lazy(() => import("./components/Elements/KycCommDirect/KycCommDirect"));
const VerifySubscriber = React.lazy(() => import("./components/Elements/KycCommDirect/VerifySubscriber"));
const AllRevenue = React.lazy(() => import("./components/Elements/AllRevenue"));
const CommunityRevenue = React.lazy(() => import("./components/Elements/CommunityRevenue"));
const MatrimonyRevenue = React.lazy(() => import("./components/Elements/MatrimonyRevenue"));
const BusinessRevenue = React.lazy(() => import("./components/Elements/BusinessRevenue"));



//Element end
//advancedui




//advancedui
//charts


  const ChartJS = React.lazy(() =>
  import("./components/Charts/ChartJS/ChartJS"))
  const JobNew = React.lazy(() =>
  import("./components/Charts/ChartJS/JobNew"))
  const JobDeleted = React.lazy(() =>
  import("./components/Charts/ChartJS/JobDeleted"))
  
  const JobArchive = React.lazy(() =>
  import("./components/Charts/ChartJS/JobArchive"))

  const Widgets = React.lazy(() =>
  import("./components/Widgets/Widgets"))

//charts
//pages
const SignUp = React.lazy(() =>
  import("./components/Pages/Authentication/SignUp/SignUp")
);
const SignIn = React.lazy(() =>
  import("./components/Pages/Authentication/SignIn/SignIn")
);
const ForgotPassword = React.lazy(() =>
  import("./components/Pages/Authentication/ForgotPassword/ForgotPassword")
);
const Lockscreen = React.lazy(() =>
  import("./components/Pages/Authentication/Lockscreen/Lockscreen")
);
const ResetPassword = React.lazy(() =>
  import("./components/Pages/Authentication/ResetPassword/ResetPassword")
);
const UnderConstruction = React.lazy(() =>
  import(
    "./components/Pages/Authentication/UnderConstruction/UnderConstruction"
  )
);
const Error404 = React.lazy(() =>
  import("./components/Pages/Authentication/404Error/404Error")
);
const Error500 = React.lazy(() =>
  import("./components/Pages/Authentication/500Error/500Error")
);
const Error501 = React.lazy(() =>
  import("./components/Pages/Authentication/501Error/501Error")
);






const Archive = React.lazy(() =>
  import("./components/Pages/ArchiveRequirement/Archive")
);
const DeletedRequirements = React.lazy(() =>
  import("./components/Pages/Deletedreq/DeletedRequirements")
);

const ClosedRequirement = React.lazy(() => import("./components/Pages/ClosedReq/ClosedRequirement"));
const NewRequirement = React.lazy(() => import("./components/Pages/NewRequirement/NewRequirement"));

//pages
//Utilities
const Extras = React.lazy(() => import("./components/Utilities/Extras/Extras"));
const Background = React.lazy(() => import("./components/Utilities/Background/Background"));
const Border = React.lazy(() => import("./components/Utilities/Border/Border"));
const Display = React.lazy(() => import("./components/Utilities/Display/Display"));
const Width = React.lazy(() => import("./components/Utilities/Width/Width"));
const Position = React.lazy(() => import("./components/Utilities/Position/Position"));
const Padding = React.lazy(() => import("./components/Utilities/Padding/Padding"));
const Margin = React.lazy(() => import("./components/Utilities/Margin/Margin"));
const Flex = React.lazy(() => import("./components/Utilities/Flex/Flex"));
const Height = React.lazy(() => import("./components/Utilities/Height/Height"));


//Utilities end
//Icons
//usermanagement
const AllUser = React.lazy(() =>
  import("./components/UserManagement/AllUser")
);
const AddUser = React.lazy(() =>
  import("./components/UserManagement/AddUser")
);
const ViewUser = React.lazy(() =>
  import("./components/UserManagement/ViewUser")
);


//Form
const Allmedia = React.lazy(() =>
  import("./components/Forms/AllMedia/AllMedia")
);

const Blog = React.lazy(() =>
  import("./components/Forms/BlogMedia/Blog")
);

const Kyc = React.lazy(() =>
  import("./components/Forms/KycMedia/Kyc")
);
const CoffeeMeet = React.lazy(() =>
  import("./components/Forms/CoffemeetMedia/CoffeeMeet")
);
const AdminMedia = React.lazy(() =>
  import("./components/Forms/AdminMedia")
);
const Event = React.lazy(() =>
  import("./components/Forms/EventMedia/Event")
);
const JobMedia = React.lazy(() =>
  import("./components/Forms/JobMedia/JobMedia")
);

const FontAwesome = React.lazy(() =>
  import("./components/Icons/FontAwesome/FontAwesome")
);
const MaterialIcons = React.lazy(() =>
  import("./components/Icons/MaterialIcons/MaterialIcons")
);
const MaterialDesignIcons = React.lazy(() =>
  import("./components/Icons/MaterialDesignIcons/MaterialDesignIcons")
);
const IonicIcons = React.lazy(() =>
  import("./components/Icons/IonicIcons/IonicIcons")
);
const Pe7Icons = React.lazy(() =>
  import("./components/Icons/Pe7Icons/Pe7Icons")
);
const SimpleLineIcons = React.lazy(() =>
  import("./components/Icons/SimpleLineIcons/SimpleLineIcons")
);
const ThemifyIcons = React.lazy(() =>
  import("./components/Icons/ThemifyIcons/ThemifyIcons")
);
const TypiconsIcons = React.lazy(() =>
  import("./components/Icons/TypiconsIcons/TypiconsIcons")
);
const WeatherIcons = React.lazy(() =>
  import("./components/Icons/WeatherIcons/WeatherIcons")
);
const BootstrapIcons = React.lazy(() =>
  import("./components/Icons/BootstrapIcons/BootstrapIcons")
);
const FeatherIcons = React.lazy(() =>
  import("./components/Icons/FeatherIcons/FeatherIcons")
);
const FlagIcons = React.lazy(() =>
  import("./components/Icons/FlagIcons/FlagIcons")
);
const CommunityAds = React.lazy(() =>
  import("./components/Icons/CommunityAds")
);
const AddNewads = React.lazy(() =>
  import("./components/Icons/AddNewads")
);
const ViewCommunityAd = React.lazy(() =>
  import("./components/Icons/ViewCommunityAd")
);
//Icons end

const DefaultTables = React.lazy(() =>
  import("./components/Tables/DefaultTables/DefaultTables")
);
const AddSubscription = React.lazy(() =>
  import("./components/Tables/AddSubscription")
);
const ViewSubscription = React.lazy(() =>
  import("./components/Tables/ViewSubscription")
);
const DataTables = React.lazy(() =>
  import("./components/Tables/DataTables/DataTables")
);
const AddNameSpell = React.lazy(() =>
  import("./components/Tables/AddNameSpell")
);
const AddSubCast = React.lazy(() =>
  import("./components/Tables/AddSubCast")
);
const SubCast = React.lazy(() =>
  import("./components/Tables/SubCast")
);
const Discount = React.lazy(() =>
  import("./components/Tables/Discount")
);
const AddDiscount = React.lazy(() =>
  import("./components/Tables/AddDiscount")
);
const ViewDiscount = React.lazy(() =>
  import("./components/Tables/ViewDiscount")
);
const Referral = React.lazy(() =>
  import("./components/Tables/Referral")
);
const EditReferal = React.lazy(() =>
  import("./components/Tables/EditReferal")
);
const AuthLogin = React.lazy(() => import("./Authentication/Login"));
const AuthSignup = React.lazy(() => import("./Authentication/Signup"))
//Form
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.Fragment>
  <HashRouter>
    <React.Suspense fallback={<Loader/>}>
      <Routes>
        <Route path={`${process.env.PUBLIC_URL}/`} element={<Auth />}>
          <Route index element={<AuthLogin />} />
          
          <Route
            path={`${process.env.PUBLIC_URL}/authentication/login`}
            element={<AuthLogin />}
          />
            <Route
            path={`${process.env.PUBLIC_URL}/authentication/signup`}
            element={<AuthSignup />}
          />
          </Route> 
          
        <Route path={`${process.env.PUBLIC_URL}/`} element={<App />}>
        <Route
              path={`${process.env.PUBLIC_URL}/`}
              element={<Dashboard />}
            />
           <Route
            path={`${process.env.PUBLIC_URL}/widgets`}
            element={<Widgets />}
          /> 
          <Route>
            <Route
              path={`${process.env.PUBLIC_URL}/dashboard/dashboard-1`}
              element={<Dashboard />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/dashboard/dashboard-2`}
              element={<Dashboard2 />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/dashboard/dashboard-3`}
              element={<Dashboard3 />}
            />
          </Route>
          <Route>
            <Route
              path={`${process.env.PUBLIC_URL}/app/FreeDirectory`}
              element={<FreeDirectory />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/app/BusinessDirectory`}
              element={<BusinessDirectory />}
            />
            
            <Route
              path={`${process.env.PUBLIC_URL}/app/filedetails`}
              element={<Filedetails />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/app/filemanager`}
              element={<Filemanager />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/app/filemanager1`}
              element={<Filemanager1 />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/app/Subscribers`}
              element={<Subscribers />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/app/viewSubscriber/:id`}
              element={<ViewSubscriber />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/app/notification`}
              element={<Notification />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/app/widgetNotification`}
              element={<Widgetnotification />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/app/Matrimony`}
              element={<Matrimony />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/app/ViewMatrimonySubscriber/:id`}
              element={<ViewMatrimonySubscriber />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/app/treeview`}
              element={<Treeview />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/app/PaidDirectory`}
              element={<PaidDirectory />}
            />
          </Route>
          <Route>
            <Route
              path={`${process.env.PUBLIC_URL}/elements/KycCommDirect`}
              element={<KycCommDirect />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/elements/AllRevenue`}
              element={<AllRevenue />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/elements/CommunityRevenu`}
              element={<CommunityRevenue />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/elements/MatrimonyRevenu`}
              element={<MatrimonyRevenue />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/elements/BusinessRevenu`}
              element={<BusinessRevenue />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/elements/VerifySubscriber/:id`}
              element={<VerifySubscriber />}
            />
            
          </Route>
          <Route>
          <Route
              path={`${process.env.PUBLIC_URL}/UserManagement/AllUser`}
              element={<AllUser />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/UserManagement/AddUser`}
              element={<AddUser />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/UserManagement/ViewUser/:id`}
              element={<ViewUser />}
            />
          </Route>
          <Route>
            
          </Route>
          <Route>
            <Route
              path={`${process.env.PUBLIC_URL}/pages/ClosedRequirement`}
              element={<ClosedRequirement />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/pages/NewRequirement`}
              element={<NewRequirement />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/pages/Archive`}
              element={<Archive />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/pages/DeletedRequirements`}
              element={<DeletedRequirements />}
            />
            
            
          </Route>
          <Route>
            
            
            
          </Route>
          <Route>
            
            </Route>
          <Route>
            <Route
              path={`${process.env.PUBLIC_URL}/icon/fontAwesome`}
              element={<FontAwesome />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/icon/materialIcons`}
              element={<MaterialIcons />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/icon/materialDesignIcons`}
              element={<MaterialDesignIcons />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/icon/ionicIcons`}
              element={<IonicIcons />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/icon/typiconsIcons`}
              element={<TypiconsIcons />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/icon/weatherIcons`}
              element={<WeatherIcons />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/icon/featherIcons`}
              element={<FeatherIcons />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/icon/flagIcons`}
              element={<FlagIcons />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/icon/pe7Icons`}
              element={<Pe7Icons />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/icon/simpleLineIcons`}
              element={<SimpleLineIcons />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/icon/themifyIcons`}
              element={<ThemifyIcons />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/icon/bootstrapIcons`}
              element={<BootstrapIcons />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/icon/CommunityAds`}
              element={<CommunityAds />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/icon/AddNewads`}
              element={<AddNewads />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/icon/ViewCommunityAd/:id`}
              element={<ViewCommunityAd/>}
            />
          </Route>
          <Route>
            <Route
              path={`${process.env.PUBLIC_URL}/form/Allmedia`}
              element={<Allmedia />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/form/Event`}
              element={<Event />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/form/JobMedia`}
              element={<JobMedia />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/form/Blog`}
              element={<Blog />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/form/Kyc`}
              element={<Kyc />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/form/CoffeeMeet`}
              element={<CoffeeMeet />}
            />
            
            <Route
              path={`${process.env.PUBLIC_URL}/form/AdminMedia`}
              element={<AdminMedia />}
            />
          </Route>
          <Route>
            
          </Route>
          <Route>
            <Route
              path={`${process.env.PUBLIC_URL}/tables/defaultTables`}
              element={<DefaultTables />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/tables/AddSubscription`}
              element={<AddSubscription />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/tables/ViewSubscription/:id`}
              element={<ViewSubscription/>}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/tables/dataTables`}
              element={<DataTables />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/tables/AddNameSpell`}
              element={<AddNameSpell/>}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/tables/AddSubCast`}
              element={<AddSubCast/>}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/tables/SubCast`}
              element={<SubCast/>}
            />
             <Route
              path={`${process.env.PUBLIC_URL}/tables/Discount`}
              element={<Discount/>}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/tables/AddDiscount`}
              element={<AddDiscount/>}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/tables/ViewDiscount/:id`}
              element={<ViewDiscount/>}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/tables/EditReferal/:id`}
              element={<EditReferal/>}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/tables/Referral`}
              element={<Referral/>}
            />
          </Route>
          
          <Route>
            <Route
              path={`${process.env.PUBLIC_URL}/charts/chartJs`}
              element={<ChartJS />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/charts/JobNew`}
              element={<JobNew />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/charts/JobArchive`}
              element={<JobArchive />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/charts/JobDeleted`}
              element={<JobDeleted />}
            />
           
          </Route>
          <Route>
            <Route>
              <Route
                path={`${process.env.PUBLIC_URL}/utilities/background`}
                element={<Background />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/Utilities/border`}
                element={<Border />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/Utilities/display`}
                element={<Display />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/Utilities/flex`}
                element={<Flex />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/Utilities/height`}
                element={<Height />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/Utilities/margin`}
                element={<Margin />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/Utilities/padding`}
                element={<Padding />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/Utilities/position`}
                element={<Position />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/Utilities/width`}
                element={<Width />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/Utilities/extras`}
                element={<Extras />}
              />
            </Route>
            <Route
              path={`${process.env.PUBLIC_URL}/pages/Authentication/501error`}
              element={<Error501 />}
            />
          </Route> 
        </Route>
         <Route path={`${process.env.PUBLIC_URL}/`} element={<Custompages />}>
          <Route
            path={`${process.env.PUBLIC_URL}/pages/Authentication/sigin`}
            element={<SignIn />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/pages/Authentication/sigup`}
            element={<SignUp />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/pages/Authentication/forgotpassword`}
            element={<ForgotPassword />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/pages/Authentication/resetpassword`}
            element={<ResetPassword />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/pages/Authentication/lockscreen`}
            element={<Lockscreen />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/pages/Authentication/underconstruction`}
            element={<UnderConstruction />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/pages/Authentication/404error`}
            element={<Error404 />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/pages/Authentication/500error`}
            element={<Error500 />}
          />
          <Route path="*" element={<Error404 />} />
        </Route> 
        
         <Route>
          <Route
            path={`${process.env.PUBLIC_URL}/pages/switcher/switcher-1`}
            element={<Switcherapp />}
          />
        </Route> 
        <Route></Route>
      </Routes>
    </React.Suspense>
    <ToastContainer autoClose={2500}  />

  </HashRouter>
</React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
